import React from "react";

const assuranceObligatoire = (
  <li>
    <p className="offre">Assurance obligatoire</p>
    <p className="desc">
      Responsabilité civile: dommages corporels et matériels avec un tiers.
      Défense pénale et recours (dans la limite des plafonds prévus aux
      Conditions Générales).
    </p>
  </li>
);

const sosFourriere = (
  <li>
    <p className="offre">S.O.S Fourrière</p>
    <p className="desc">Localisation de l'engin enlevé par la fourrière</p>
  </li>
);

const blessureDuRider = (
  <li>
    <p className="offre">Blessure du rider</p>
    <p className="desc">
      Dommages corporels du conducteur jusqu'à 50&nbsp;000€
    </p>
  </li>
);

const volEtCasse = (
  <li>
    <p className="offre">Vol et casse de l'engin</p>
    <p className="desc">
      Remboursement de l'engin en cas de vol ou de casse, jusqu'à 8&nbsp;000€
    </p>
  </li>
);

/*const reparationEtMaintenance = nbParAn => (
  <li>
    <p className="offre">Réparation et maintenance:</p>
    <p className="desc">Une prestation par an dans la limite de 60€</p>
    {nbParAn > 0 && (
      <div className="complements">
        <Tag color="grey">{nbParAn} par an</Tag>
      </div>
    )}
  </li>
)

const equipementsEtProtection = tags => (
  <li>
    <p className="offre">Équipements de protection:</p>
    <div className="complements">
      {tags.map(t => (
        <Tag key={t} color="grey">
          {t}
        </Tag>
      ))}
    </div>
  </li>
)*/

const bonsAchat = (montant) => (
  <li>
    <p className="offre">Bons d'achat</p>
    <p className="desc">
      On vous rembourse {montant}€ par an en bons d’achat dans votre magasin
      préféré
    </p>
  </li>
);

export default function ShowOffer({ offer, withTitle }) {
  let template;
  let title;
  switch (offer.trim()) {
    case "droit_de_rouler":
      title = "Droit de rouler";
      template = (
        <ul className="offres">
          {assuranceObligatoire}
          {bonsAchat(5)}
          {sosFourriere}
        </ul>
      );
      break;
    case "free_rider":
      title = "Free Rider";
      template = (
        <ul className="offres">
          {assuranceObligatoire}
          {bonsAchat(50)}
          {sosFourriere}
          {blessureDuRider}
        </ul>
      );
      break;
    case "esprit_tranquille":
      title = "Esprit tranquille";
      template = (
        <ul className="offres">
          {assuranceObligatoire}
          {bonsAchat(75)}
          {sosFourriere}
          {blessureDuRider}
          {volEtCasse}
        </ul>
      );
      break;
    default:
  }
  return withTitle ? (
    <div>
      <h2>Offre &laquo;{title}&raquo;</h2>
      {template}
    </div>
  ) : (
    template
  );
}
