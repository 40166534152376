// CheckoutForm.js
import React, { useState, useEffect } from "react";
import {
  CardElement,
  ElementsConsumer,
  /*CardNumberElement,
  CardExpiryElement,
  CardCvcElement,*/
} from "@stripe/react-stripe-js";

import { Row, Col, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./paymentForm.less";
import cards from "../images/cards.png";
import SignForm from "./signForm";

function CheckoutForm(props) {
  const [stripeIntent, setStripeIntent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    async function generatePayment() {
      let tmpAmount;
      switch (props.contractValues.offer.trim()) {
        case "droit_de_rouler":
          tmpAmount = 499 * 2;
          break;
        case "esprit_tranquille":
          tmpAmount = Math.ceil(
            ((0.0075 * parseFloat(props.contractValues.vehicle_price) + 15.77) *
              2 +
              5.9) *
              100
          );
          break;
        default:
      }
      const paymentIntent = await fetch(
        process.env.GATSBY_API_URL + "/generate_payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: tmpAmount,
          }),
        }
      );
      const stripeIntent = await paymentIntent.json();
      setAmount(tmpAmount);
      setStripeIntent(stripeIntent);
    }
    generatePayment();
  }, [props.contractValues.offer, props.contractValues.vehicle_price]);

  async function onSubmitStripePayment(e) {
    e.preventDefault();

    const { stripe, elements } = props;
    setIsLoading(true);
    const { client_secret: clientSecret } = stripeIntent;

    const cardElement = elements.getElement(CardElement);

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      console.error(error);
      return setIsLoading(false);
    }

    props.onPaymentCompleted();
    setIsLoading(false);
  }

  const { isPaymentCompleted, isSignatureCompleted } = props;

  if (isSignatureCompleted) {
    return (
      <Row gutter={16}>
        <Col span={24}>
          <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>
            Bienvenue dans la communauté Patinette! Ensemble, construisons le
            futur de l'assurance.
          </p>
        </Col>
        <Col span={24}>Merci beaucoup pour votre confiance.</Col>
      </Row>
    );
  }
  return (
    <form onSubmit={onSubmitStripePayment}>
      {isPaymentCompleted ? (
        <Row gutter={16}>
          <p style={{ color: "green", fontSize: "2em", fontWeight: "bold" }}>
            Votre paiement est confirmé <CheckOutlined />
          </p>
          <p>
            Il ne vous reste plus qu'à signer le contrat Patinette que nous
            venons de vous envoyer par email.
          </p>
          {
            <SignForm
              onSignCompleted={props.onSignCompleted}
              contractValues={props.contractValues}
            />
          }
        </Row>
      ) : (
        <Row gutter={16} className="paymentForm">
          <img className="cards" src={cards} alt="Visa, Mastercard" />
          <h5>Montant à payer : {amount / 100}€ TTC</h5>
          <h6>
            Le paiement comprend 2 mois ainsi que la cotisation au Fonds de
            Garantie des Assurances Obligatoires
          </h6>
          <p>
            Afin de valider votre adhésion à l'offre, merci de renseigner votre
            numéro de carte bancaire:
          </p>
          <Col span={24}>
            <CardElement className="ant-input ant-input-lg ant-input-padding-top" />
          </Col>
          <Button
            className="payment-btn"
            size="large"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Valider le paiement
          </Button>
        </Row>
      )}
    </form>
  );
}

export default function FinalForm(props) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm {...props} elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
}
