import React, { useState } from "react";

import "../styles/centered-box.less";
import "../styles/recapitulatif.less";

import { getLocalFormValues, navigate } from "../utils";

import { Layout, Row, Col, Button } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";
import PaymentForm from "../components/paymentForm";
import ShowOffer from "../components/ShowOffer.js";
import { loadStripe } from "@stripe/stripe-js";

const { Elements } =
  typeof window !== "undefined" ? require("@stripe/react-stripe-js") : {};

const stripePromise =
  typeof window !== "undefined" &&
  loadStripe(
    ["localhost", "127.0.0.1"].includes(document.location.host.split(":")[0])
      ? "pk_test_MqNWNNtUgPx39TBn9Rs7WvNv00jgXNjm42"
      : "pk_live_zgJOtbFxozXZvs9R82yfOFCB008Xdr25Om"
  );

const { Content } = Layout;

export default function SubscriptionForm() {
  const initFormValues = getLocalFormValues();
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [isSignatureCompleted, setIsSignatureCompleted] = useState(false);

  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    navigate("/");
    return null;
  }

  const {
    firstname,
    name,
    email,
    street_number,
    route,
    postal_code,
    locality,
    vehicle_type,
    vehicle_brand,
    vehicle_model,
    vehicle_price,
    offer,
    request_id: requestId,
  } = initFormValues;

  function round2decimals(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  const cotisationMensuelle =
    offer === "droit_de_rouler"
      ? 4.99
      : round2decimals(15.77 + 0.0075 * vehicle_price);

  return (
    <Layout className="centered-box recapitulatif">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col xl={16} xs={24} className="main">
            <h1>On y est presque !</h1>
            {!isPaymentCompleted && (
              <h4 style={{ margin: "20px 0 40px" }}>
                Renseignez votre CB ici après avoir vérifié les informations, vous serez débités de deux mois de
                contrat après signature de votre contrat
              </h4>
            )}
            <Row id="recap">
              <Col lg={12} xs={24}>

                <ShowOffer offer={offer} withTitle />
                <br />
                <h4>Cotisation mensuelle: {cotisationMensuelle} € TTC</h4>
                <br />

                <h4>Véhicule "{vehicle_type}":</h4>
                <p>
                  {vehicle_model} - {vehicle_brand} - Prix: {vehicle_price}€
                  <br />
                </p>
                <p>Vitesse inférieure ou égale à 25km/h</p>

                <br />
                <h4>Bénéficiaire:</h4>
                <p>
                  {firstname} {name}
                  <br />
                  {street_number} {route}
                  <br />
                  {postal_code} {locality}
                  <br />
                  {email}
                </p>
                {!isPaymentCompleted && (
                  <Button
                    className="edit-info"
                    type="primary"
                    size="small"
                    onClick={() => navigate(`/form1-identity#${requestId}`)}
                  >
                    Modifier les informations
                  </Button>
                )}
              </Col>
              {stripePromise && (
                <Col id="paiement" lg={10} xs={24}>
                  <h2>Paiement</h2>
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      clientName={`${firstname} ${name}`}
                      clientEmail={email}
                      contractValues={initFormValues}
                      onPaymentCompleted={() => setIsPaymentCompleted(true)}
                      onSignCompleted={() => setIsSignatureCompleted(true)}
                      isPaymentCompleted={isPaymentCompleted}
                      isSignatureCompleted={isSignatureCompleted}
                    />
                  </Elements>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
