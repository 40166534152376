import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import axios from "axios";

export default function SignForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSignatureGenerated, setIsSignatureGenerated] = useState(false);

  useEffect(() => {
    async function prepareContract() {
      setIsLoading(true);
      setIsSignatureGenerated(true);
      axios
        .post(
          process.env.GATSBY_API_URL + "/generate_signature",
          props.contractValues
        )
        .then((response) => {
          setIsLoading(false);
          const { success } = response.data;
          if (!success) {
            console.error(response);
            throw new Error("Error from signature here");
          }
        });
    }
    if (!isSignatureGenerated) {
      prepareContract();
    }
  }, [props.contractValues, isSignatureGenerated]);

  return isLoading ? (
    <p style={{ margin: "auto" }}>
      <Spin />
    </p>
  ) : null;
}
